import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './index';

export type FeatureType = {
  [key: string]: boolean;
};

export interface FeatureStateInterface {
  features: FeatureType;
}

export const initialState: FeatureStateInterface = {
  features: {
    intercom: true,
  },
};

const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    updateFeatures: (state, action: PayloadAction<FeatureType>) => {
      localStorage.setItem('features', JSON.stringify(action.payload));
      state.features = action.payload;
      if (action.payload.intercom) {
        document.documentElement.style.setProperty('--intercom-display', 'block');
      } else {
        document.documentElement.style.setProperty('--intercom-display', 'none');
      }
    },
  },
});

export const { updateFeatures } = featureSlice.actions;
export const selectFeatures = (state: RootState) => state.feature.features;
export default featureSlice.reducer;
