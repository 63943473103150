import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRoutes, BrowserRouter } from 'react-router-dom';
import routes from './routes';
import Loading from '@/components/loading';
import packageJson from '../package.json';
import { useAppSelector } from './store/hooks';
import { selectAuthUser, selectCurrentUser } from './store/userSlice';
import { insertNewRelicScript } from './scripts/newRelic';

function RouteElement() {
  return useRoutes(routes);
}

const App: React.FC = () => {
  const { isLoading, error } = useAuth0();
  const [isGmapLoaded, setIsGmapLoaded] = useState(false);
  const { sub, name, email } = useAppSelector(selectAuthUser);
  const currentUser = useAppSelector(selectCurrentUser);

  const initMap = () => {
    setIsGmapLoaded(true);
  };

  useEffect(() => {
    if (sub && currentUser) {
      const userId = sub.split('|')[1];
      if (userId) {
        window.analytics.identify(userId, {
          name,
          email,
          role: currentUser.relationships.roles.data.name,
          accounts: currentUser.relationships.accounts.data.map((a) => a.id),
        });
      }
    }
  }, [sub, currentUser]);

  useEffect(() => {
    function appendGmapScript() {
      if (isGmapLoaded) return;
      (window as any).initMap = initMap;
      const gmapScriptEl = document.createElement(`script`);
      gmapScriptEl.setAttribute('async', 'async');
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`;
      document.querySelector(`head`)?.insertAdjacentElement(`beforeend`, gmapScriptEl);
    }

    function insertVersion() {
      const versionElement = document.querySelector('meta[name="version"]');
      if (!versionElement) {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'version');
        meta.setAttribute('content', packageJson.version);
        document.head.insertBefore(meta, document.head.firstChild);
      } else if (versionElement.getAttribute('content') !== packageJson.version) {
        versionElement.setAttribute('content', packageJson.version);
      }
    }

    function initSegment() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        !function () {
          var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
            analytics.invoked = !0; 
            analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"]; 
            analytics.factory = function (t) { return function () { var e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics } }; for (var t = 0; t < analytics.methods.length; t++) { var e = analytics.methods[t]; analytics[e] = analytics.factory(e) } analytics.load = function (t, e) { var n = document.createElement("script"); n.type = "text/javascript"; n.async = !0; n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(n, a); analytics._loadOptions = e }; analytics.SNIPPET_VERSION = "4.1.0";
            analytics.load("${process.env.REACT_APP_SEGMENT_WRITE_KEY}");
          }
        }();
      `;
      document.head.appendChild(script);
    }

    initSegment();
    appendGmapScript();
    insertVersion();
    insertNewRelicScript(process.env.REACT_APP_NEW_RELIC_APPLICATION_ID);
  }, []);

  if (error) {
    return <h1 className="text-center mt-56">Oops... {error.message}</h1>;
  }

  if (isLoading || !isGmapLoaded) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <RouteElement />
    </BrowserRouter>
  );
};

export default App;
